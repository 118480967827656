/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,300&display=swap');
@import '~@angular/material/theming';

html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
}

section {
  width: 100vw;
  height: 56.25vw;
  max-height: 90vh;
  margin-top: 68px;
}


.cursor {
  cursor: pointer;
}

button:focus {
  outline: none !important;
  outline: none !important;
}

// organize and separate out into componet scss files
#Melissa-S-Langhausen-Front-end-web-designer {
  background-image: url("./assets/img/home-office-336377_1920.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #f1f1f1;
  border-radius: 0;
  padding: 0;
  min-height: 100%;

  .container-fluid {
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100vh;

    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 2rem;
      color: #5bc0de;
    }
  }
}

#skillset {
  background-image: url("./assets/img/blur-1853305_1920.jpg");
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #f1f1f1;

  .container-fluid {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  h3 {
    margin-top: 60px;
  }

  h4 {
    margin-bottom: 0px;
    font-size: 1.2rem;
    color: #5bc0de;
    border-bottom: 1px solid #5bc0de;
  }

  ul {
    margin-bottom: 60px;
  }
}

#reviews {
  background-image: url("./assets/img/ipad-605420.jpg");
  height: 100%;
  max-width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #f1f1f1;
}

#reviews h3 {
  color: #f1f1f1;
  margin-top: 100px
}

#reviews .container-fluid {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.reviews {
  text-align: center;
}

.reviews .pic {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  margin: 0 auto 40px;
  border: 4px solid #5bc0de;
  overflow: hidden;
}

.reviews .pic img {
  width: 100%;
  height: auto;
}

.reviews .description {
  color: #f1f1f1;
  font-style: italic;
  line-height: 1em;
  margin-bottom: 20px;
}

.reviews .reviews-prof {
  margin: 20px 0;
}

.reviews .title {
  font-size: 20px;
  color: #5bc0de;
  margin-right: 20px;
  text-transform: capitalize;
}

.reviews .title:after {
  content: "";
  margin-left: 30px;
  border-right: 1px solid #5bc0de;
}

.reviews .reviews-prof small {
  display: inline-block;
  color: #8a9aad;
  font-size: 17px;
  text-transform: capitalize;
}

.owl-theme .owl-controls .owl-buttons div {
  background: transparent;
  opacity: 1;
}

.owl-buttons {
  position: absolute;
  top: 8%;
  width: 100%;
}

.owl-prev {
  position: absolute;
  left: 30%;
}

.owl-next {
  position: absolute;
  right: 30%;
}

.owl-prev:after,
.owl-next:after {
  content: "\f060";
  font-family: 'FontAwesome';
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #808080;
  transition: all 0.15s ease 0s;
}

.owl-next:after {
  content: "\f061";
}

@media only screen and (max-width: 479px) {
  .owl-prev {
    left: 10%;
  }

  .owl-next {
    right: 10%;
  }
}

#billingBill {
  height: 620px;
  background-image: url("./assets/img/home-office-336377_1920.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #f1f1f1;
  border-radius: 0;
  padding: 0;
}

section h3 {
  border-top: 4px solid #5bc0de;
  padding-top: 10px;
  margin-bottom: 40px;
  font-size: 1.5rem !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #5bc0de !important;
}